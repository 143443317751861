import React, { useState } from 'react';
import axios from 'axios';
import s from './style.module.css';

const Footer = () => {
  const [bugDescription, setBugDescription] = useState('');
  const [message, setMessage] = useState('');
  const [showBugForm, setShowBugForm] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Sending bug report:', bugDescription);
      
      // Remplacer fetch par axios
      const response = await axios.post('https://api.clubcrm.fr/api/send-bug-report', {
        description: bugDescription
      });

      if (response.status === 200) {
        setMessage('Merci pour votre signalement. Nous allons examiner le problème.');
        setBugDescription('');
      } else {
        setMessage('Erreur lors de l\'envoi du signalement. Veuillez réessayer.');
      }
    } catch (error) {
      console.error('Error sending bug report:', error);
      setMessage('Erreur lors de l\'envoi du signalement. Veuillez réessayer.');
    }
  };

  return (
    <footer className={s.footer}>
      <p>© 2024 DLweb47. Tous droits réservés.</p>
      <p>
        Pour tout problème, contactez le webmaster à
        <a href="mailto:webmaster@clubcrm.fr"> webmaster@clubcrm.fr</a>
      </p>
      <p className={s.bugLink}>
        <a href="#!" onClick={() => setShowBugForm(!showBugForm)}>
          Signaler un bug
        </a>
      </p>
      {showBugForm && (
        <form onSubmit={handleSubmit} className={s.bugForm}>
          <label htmlFor="bugDescription">Signaler un bug :</label>
          <textarea
            id="bugDescription"
            value={bugDescription}
            onChange={(e) => setBugDescription(e.target.value)}
            placeholder="Décrivez le problème ici..."
            required
          />
          <button type="submit">Envoyer</button>
        </form>
      )}
      {message && <p className={s.thankYouMessage}>{message}</p>}
    </footer>
  );
};

export default Footer;
