import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFirestore, collection, query, where, getDocs, doc, updateDoc } from "firebase/firestore"; // Ajout correct de Firestore
import s from "./style.module.css";
import { Link } from "react-router-dom";
import Footer from "components/Footer/Footer";

function PaymentSuccess() {
  const [loading, setLoading] = useState(true);
  const [sessionId, setSessionId] = useState(null);
  const [amount, setAmount] = useState(null);
  const navigate = useNavigate();
  const db = getFirestore(); // Correcte initialisation de Firestore

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    const sessionId = queryParams.get("session_id");

    if (sessionId) {
      setSessionId(sessionId);
      fetchSessionDetails(sessionId);
    } else {
      navigate("/");
    }
  }, [navigate]);

  const fetchSessionDetails = async (sessionId) => {
    try {
      // 1. Récupérer les détails de la session Stripe
      const response = await fetch(`https://api.clubcrm.fr/api/payment-session/${sessionId}`);
      const data = await response.json();

      setAmount(data.amount_total / 100); // Assurez-vous que l'API retourne le montant en centimes

      // 2. Trouver l'utilisateur dans Firebase via le stripeCustomerId
      const stripeCustomerId = data.customer;

      const usersQuery = query(
        collection(db, "users"),
        where("stripeCustomerId", "==", stripeCustomerId)
      );
      const querySnapshot = await getDocs(usersQuery);

      if (!querySnapshot.empty) {
        const userDoc = querySnapshot.docs[0];
        const userId = userDoc.id;

        // 3. Mettre à jour le statut de souscription dans Firestore
        await updateDoc(doc(db, "users", userId), {
          subscriptionStatus: "active",
          updatedAt: new Date().toISOString(),
        });

        console.log("Mise à jour du statut de l'utilisateur Firebase :", userId);
      } else {
        console.error("Aucun utilisateur trouvé avec cet ID Stripe.");
      }

      setLoading(false);
    } catch (error) {
      console.error("Erreur lors de la récupération des détails de la session :", error);
      setLoading(false);
    }
  };

  return (
    <div className={s.container}>
      <h1 className={s.title}>Paiement Réussi</h1>
      {loading ? (
        <p>Chargement des détails de la transaction...</p>
      ) : (
        <>
          <div className={s.successMessage}>
            <h2>Merci pour votre paiement !</h2>
            <p>Votre transaction a été effectuée avec succès.</p>
            <p>Montant : {amount}€</p>
            <p>ID de la transaction : {sessionId}</p>
          </div>
          <div className={s.buttonContainer}>
            <Link to="/dashboard" className={s.button}>
              J'accède à mon tableau de bord
            </Link>
          </div>
        </>
      )}
      <Footer />
    </div>
  );
}

export default PaymentSuccess;
