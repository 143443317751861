import React from "react";
import { Link } from "react-router-dom";
import Login from "../Login/Login";
import s from "./style.module.css";
import Footer from "components/Footer/Footer";

function Home() {
  return (
    <div className={s.container}>
      <div className={s.content}>
        <h1 className={s.title}>Bienvenue sur Club CRM</h1>
        <p className={s.paraf}>Votre fichier client qui vous suit partout</p>
        <Login />
        <Link to="/signup" className={s.signupLink}>
          Inscription
        </Link>
      </div>
      <Footer />
    </div>
  );
}

export default Home;
