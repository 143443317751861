// Layout.jsx
import React from 'react';
import Footer from '../Footer/Footer';
import s from './style.module.css';

const Layout = ({ children }) => {
  return (
    <div className={s.layoutContainer}>
      <div className={s.contentWrapper}>
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
