import React, { useState, useEffect } from "react";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth"; // Correct import
import { useNavigate, useLocation } from "react-router-dom";
import s from "./style.module.css";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [showModal, setShowModal] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const auth = getAuth(); // Correct initialization of auth

  useEffect(() => {
    // Vérifie si la modale doit être affichée après l'inscription
    if (location.state && location.state.showModal) {
      setShowModal(true);
      setEmail(location.state.email);
    }
  }, [location]);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/dashboard");
    } catch (error) {
      setError("Erreur lors de la connexion : " + error.message);
    }
  };

  return (
    <div className={s.container}>
      <h1>Connexion</h1>
      <form onSubmit={handleLogin} className={s.form}>
        <input
          className={s.mail}
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
          required
        />
        <input
          className={s.password}
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Mot de passe"
          required
        />
        <button type="submit" className={s.btn}>
          Connexion
        </button>
      </form>
      {error && <p className={s.error}>{error}</p>}

      {/* Modal affiché après l'inscription */}
      {showModal && (
        <div className={s.modal}>
          <div className={s.modalContent}>
            <h2>Vérifiez votre e-mail</h2>
            <p>
              Un e-mail de vérification a été envoyé à {email}. Veuillez cliquer sur le lien de cet e-mail pour confirmer votre adresse avant de vous connecter. Si vous ne l'avez pas reçu vérifiez vos spams sinon merci de signaler le bug.
            </p>
            <button onClick={() => setShowModal(false)} className={s.btn}>
              OK
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default Login;
