import React, { useState, useEffect } from "react";
import { auth, db } from "../../firebase";
import {
  collection,
  addDoc,
  doc,
  setDoc,
  updateDoc,
  deleteDoc,
  getDocs,
  query,
  where,
} from "firebase/firestore";
import * as XLSX from "xlsx";
import s from "./style.module.css";
import { formatName, formatFirstName } from "../../utils/formatUtils";

function AddClient({ editClient, onSave, onDelete }) {
  const [clientData, setClientData] = useState({
    adresse: "",
    birthday: "",
    commentaires: "",
    date1erContact: "",
    dateDeRelance: "",
    dateDernierAtelier: "",
    mail: "",
    nom: "",
    prenom: "",
    produitFavoris1: "",
    produitFavoris2: "",
    qualification: [],
    recommandation: "",
    recrutementPossible: false,
    tel: "",
  });
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (editClient) {
      setClientData({
        ...editClient,
        nom: formatName(editClient.nom),
        prenom: formatFirstName(editClient.prenom),
      });
    } else {
      setClientData({
        adresse: "",
        birthday: "",
        commentaires: "",
        date1erContact: "",
        dateDeRelance: "",
        dateDernierAtelier: "",
        mail: "",
        nom: "",
        prenom: "",
        produitFavoris1: "",
        produitFavoris2: "",
        qualification: [],
        recommandation: "",
        recrutementPossible: false,
        tel: "",
      });
    }
  }, [editClient]);

  const handleChange = (e) => {
    const { name, value, type, checked, selectedOptions } = e.target;
    if (type === "checkbox" && name === "recrutementPossible") {
      setClientData((prev) => ({ ...prev, [name]: checked }));
    } else if (name === "qualification") {
      const newQualifications = Array.from(
        selectedOptions,
        (option) => option.value
      );
      setClientData((prev) => ({ ...prev, [name]: newQualifications }));
    } else {
      setClientData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleAddClient = async () => {
    try {
      const formattedData = {
        ...clientData,
        nom: formatName(clientData.nom),
        prenom: formatFirstName(clientData.prenom),
      };

      const userRef = doc(db, "users", auth.currentUser.uid);
      await setDoc(userRef, {}, { merge: true });

      const clientsCollectionRef = collection(userRef, "clients");

      // Check for existing client
      const q = query(
        clientsCollectionRef,
        where("nom", "==", formattedData.nom),
        where("prenom", "==", formattedData.prenom)
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        alert("Client déjà existant.");
        return;
      }

      const docRef = await addDoc(clientsCollectionRef, {
        ...formattedData,
        owner: auth.currentUser.uid,
      });
      console.log("Document written with ID: ", docRef.id);
      alert("Client ajouté avec succès !");
      setClientData({
        adresse: "",
        birthday: "",
        commentaires: "",
        date1erContact: "",
        dateDeRelance: "",
        dateDernierAtelier: "",
        mail: "",
        nom: "",
        prenom: "",
        produitFavoris1: "",
        produitFavoris2: "",
        qualification: [],
        recommandation: "",
        recrutementPossible: false,
        tel: "",
      });
    } catch (e) {
      console.error("Error adding document: ", e);
      alert("Erreur lors de l'ajout du client.");
    }
  };

  const handleSaveClient = async () => {
    try {
      const formattedData = {
        ...clientData,
        nom: formatName(clientData.nom),
        prenom: formatFirstName(clientData.prenom),
      };
      const clientRef = doc(
        db,
        "users",
        auth.currentUser.uid,
        "clients",
        editClient.id
      );
      await updateDoc(clientRef, formattedData);
      onSave(formattedData);
    } catch (e) {
      console.error("Error updating document: ", e);
      alert("Erreur lors de la mise à jour du client.");
    }
  };

  const handleDeleteClient = async () => {
    try {
      const clientRef = doc(
        db,
        "users",
        auth.currentUser.uid,
        "clients",
        editClient.id
      );
      await deleteDoc(clientRef);
      onDelete(editClient.id);
    } catch (e) {
      console.error("Error deleting document: ", e);
      alert("Erreur lors de la suppression du client.");
    }
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = () => {
    if (!file) {
      alert("Veuillez choisir un fichier.");
      return;
    }

    const reader = new FileReader();
    reader.onload = async (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = XLSX.utils.sheet_to_json(worksheet);

        for (const client of jsonData) {
          try {
            client.recrutementPossible =
              (client.recrutementPossible || "").toLowerCase() === "oui";
            client.qualification =
              typeof client.qualification === "string"
                ? client.qualification.split(",").map((item) => item.trim())
                : [];

            const formattedClient = {
              adresse: client.adresse || "",
              birthday: client.birthday || "",
              commentaires: client.commentaires || "",
              date1erContact: client['Date du Premier Contact'] || "",
              dateDeRelance: client['Date de Relance'] || "",
              dateDernierAtelier: client['Date du Dernier Atelier'] || "",
              mail: client.Email || "",
              nom: formatName(client.Nom || ""),
              prenom: formatFirstName(client.Prénom || ""),
              produitFavoris1: client['Produit Favori 1'] || "",
              produitFavoris2: client['Produit Favori 2'] || "",
              qualification: client.qualification,
              recommandation: client.Recommandation || "",
              recrutementPossible: client.recrutementPossible,
              tel: client.Téléphone || "",
            };

            const userRef = doc(db, "users", auth.currentUser.uid);
            await setDoc(userRef, {}, { merge: true });

            const clientsCollectionRef = collection(userRef, "clients");

            // Check for existing client
            const q = query(
              clientsCollectionRef,
              where("nom", "==", formattedClient.nom),
              where("prenom", "==", formattedClient.prenom)
            );
            const querySnapshot = await getDocs(q);
            if (!querySnapshot.empty) {
              console.log("Client déjà existant.");
              continue;
            }

            const docRef = await addDoc(clientsCollectionRef, {
              ...formattedClient,
              owner: auth.currentUser.uid,
            });
            console.log("Client added with ID: ", docRef.id);
          } catch (e) {
            console.error("Error adding client: ", e);
          }
        }
        alert("Clients ajoutés avec succès !");
      } catch (e) {
        console.error("Error processing file: ", e);
        alert("Erreur lors du traitement du fichier.");
      }
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <div className={s.container}>
      <h2>{editClient ? "Modifier Client" : "Ajouter un nouveau client"}</h2>
      <div className={s.formGroup}>
        <label htmlFor="nom">Nom</label>
        <input
          type="text"
          name="nom"
          value={clientData.nom}
          onChange={handleChange}
          placeholder="Nom"
        />
      </div>
      <div className={s.formGroup}>
        <label htmlFor="prenom">Prénom</label>
        <input
          type="text"
          name="prenom"
          value={clientData.prenom}
          onChange={handleChange}
          placeholder="Prénom"
        />
      </div>
      <div className={s.formGroup}>
        <label htmlFor="adresse">Adresse</label>
        <input
          type="text"
          name="adresse"
          value={clientData.adresse}
          onChange={handleChange}
          placeholder="Adresse"
        />
      </div>
      <div className={s.formGroup}>
        <label htmlFor="tel">Téléphone</label>
        <input
          type="text"
          name="tel"
          value={clientData.tel}
          onChange={handleChange}
          placeholder="Téléphone"
        />
      </div>
      <div className={s.formGroup}>
        <label htmlFor="mail">Email</label>
        <input
          type="email"
          name="mail"
          value={clientData.mail}
          onChange={handleChange}
          placeholder="Email"
        />
      </div>
      <div className={s.formGroup}>
        <label htmlFor="birthday">Date de naissance</label>
        <input
          type="date"
          name="birthday"
          value={clientData.birthday}
          onChange={handleChange}
        />
      </div>
      <div className={s.formGroup}>
        <label htmlFor="date1erContact">Date du premier contact</label>
        <input
          type="date"
          name="date1erContact"
          value={clientData.date1erContact}
          onChange={handleChange}
        />
      </div>
      <div className={s.formGroup}>
        <label htmlFor="dateDeRelance">Date de relance</label>
        <input
          type="date"
          name="dateDeRelance"
          value={clientData.dateDeRelance}
          onChange={handleChange}
        />
      </div>
      <div className={s.formGroup}>
        <label htmlFor="dateDernierAtelier">Date du dernier atelier</label>
        <input
          type="date"
          name="dateDernierAtelier"
          value={clientData.dateDernierAtelier}
          onChange={handleChange}
        />
      </div>
      <div className={s.formGroup}>
        <label htmlFor="produitFavoris1">Produit favori 1</label>
        <input
          type="text"
          name="produitFavoris1"
          value={clientData.produitFavoris1}
          onChange={handleChange}
          placeholder="Produit favori 1"
        />
      </div>
      <div className={s.formGroup}>
        <label htmlFor="produitFavoris2">Produit favori 2</label>
        <input
          type="text"
          name="produitFavoris2"
          value={clientData.produitFavoris2}
          onChange={handleChange}
          placeholder="Produit favori 2"
        />
      </div>
      <div className={s.formGroup}>
        <label htmlFor="recommandation">Recommandation</label>
        <input
          type="text"
          name="recommandation"
          value={clientData.recommandation}
          onChange={handleChange}
          placeholder="Recommandation"
        />
      </div>
      <div className={s.formGroup}>
        <label>
          Recrutement possible :
          <input
            type="checkbox"
            name="recrutementPossible"
            checked={clientData.recrutementPossible}
            onChange={handleChange}
          />
        </label>
      </div>
      <div className={s.formGroup}>
        <label htmlFor="qualification">Qualification :</label>
        <select
          multiple
          name="qualification"
          value={clientData.qualification}
          onChange={handleChange}
          size="2"
        >
          <option value="Client(e)">Client(e)</option>
          <option value="hôte(sse)">Hôte(sse)</option>
          <option value="prospect">Prospect</option>
          <option value="autre">autre</option>
        </select>
      </div>
      <div className={s.formGroup}>
        <label htmlFor="commentaires">Commentaires</label>
        <input
          type="text"
          name="commentaires"
          value={clientData.commentaires}
          onChange={handleChange}
          placeholder="Commentaires"
        />
      </div>
      <div className={s.formActions}>
        <button onClick={editClient ? handleSaveClient : handleAddClient}>
          {editClient ? "Mettre à jour" : "Ajouter Client"}
        </button>
        {editClient && <button onClick={handleDeleteClient}>Supprimer</button>}
      </div>
      {!editClient && (
        <div className={s.massUploadContainer}>
          <h2>Ajouter des clients en masse</h2>
          <a href="/template.xlsx" download>
            <button>Télécharger le modèle</button>
          </a>
          <p>
            Une fois le modèle téléchargé remplissez le depuis votre ordinateur
            et selectionnez le fichier complété en cliquant sur choisir un
            fichier.
          </p>
          <span className={s.file}>
            <input type="file" onChange={handleFileChange} accept=".xlsx, .xls, .csv" />
          </span>
          <button onClick={handleUpload} className={s.buttonImport}>
            Importer le fichier
          </button>
          <em>
            Pour ajouter tous vos clients depuis votre fichier cliquer sur
            importer le fichier
          </em>
        </div>
      )}
    </div>
  );
}

export default AddClient;