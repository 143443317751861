import React from "react";
import { Link } from "react-router-dom";
import s from "./style.module.css";
import { useAuth } from "components/context/authContext";

function SubscriptionPage() {
  const { currentUser } = useAuth();

  const handleCheckout = async () => {
    const stripeCustomerId = currentUser?.stripeCustomerId; // Récupérer le stripeCustomerId de l'utilisateur connecté
    const priceId = "price_1Q1lZzDwn0N2zq9ow9suSlsf"; // Remplacer par l'ID du prix correct de Stripe

    console.log("stripeCustomerId:", stripeCustomerId);
    console.log("priceId:", priceId);

    if (!stripeCustomerId) {
      alert("Vous devez être connecté pour accéder à la facturation.");
      return;
    }

    try {
      // Créer une session de paiement Stripe Checkout
      const checkoutResponse = await fetch("https://api.clubcrm.fr/api/create-checkout-session", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ stripeCustomerId, priceId }), // Envoie l'ID du client et du prix à Stripe
      });

      if (!checkoutResponse.ok) {
        const errorText = await checkoutResponse.text();
        throw new Error(`Échec de la création de la session Checkout: ${errorText}`);
      }

      const checkoutData = await checkoutResponse.json();

      // Rediriger l'utilisateur vers la page de paiement Stripe Checkout
      window.location.href = checkoutData.url;
    } catch (error) {
      alert("Erreur lors de la création de la session Checkout: " + error.message);
      console.log(error);
    }
  };

  return (
    <div className={s.container}>
      <h1 className={s.title}>Choisissez votre plan d'abonnement</h1>
      <div>
        <h2 className={s.subtitle}>Abonnement Annuel</h2>
        <p>30€ par an</p>
        <button className={s.button} onClick={handleCheckout}>
          S'abonner au plan Annuel
        </button>
        <p className={s.description}>
          Le plan Annuel pourra vous être offert par votre RS sous condition du
          minimum de PV annuel requis par votre statut.
        </p>
      </div>
      <Link to="/" className={s.link}>
        Retour à l'accueil
      </Link>
    </div>
  );
}

export default SubscriptionPage;
