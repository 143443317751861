import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { doc, updateDoc } from "firebase/firestore";
import Modal from "react-modal";
import { db, auth } from "../../firebase";
import s from "./style.module.css";

function isDateMoreThanWeeksAgo(date, weeks) {
  const weeksAgo = new Date();
  weeksAgo.setDate(weeksAgo.getDate() - weeks * 7);
  return new Date(date) < weeksAgo;
}

function isBirthdayInAWeek(birthday) {
  const today = new Date();
  const birthDate = new Date(birthday);
  return (
    today.getDate() === birthDate.getDate() &&
    today.getMonth() === birthDate.getMonth()
  );
}

const NotificationBell = ({ clients }) => {
  const [notifications, setNotifications] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const [showNotifications, setShowNotifications] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  useEffect(() => {
    const newNotifications = [];

    clients.forEach((client) => {
      const now = new Date();
      const dateDeRelance = new Date(client.dateDeRelance);
      const dateDernierAtelier = new Date(client.dateDernierAtelier);
      const date1erContact = new Date(client.date1erContact);

      const isRelanceDue =
        (isDateMoreThanWeeksAgo(dateDernierAtelier, 8) ||
          isDateMoreThanWeeksAgo(date1erContact, 8)) &&
        (!client.dateDeRelance ||
          now - dateDeRelance >= 8 * 7 * 24 * 60 * 60 * 1000);
      const isBirthdayToday = isBirthdayInAWeek(client.birthday);

      if (isRelanceDue) {
        newNotifications.push({
          id: client.id,
          message: `Il est temps de relancer ${client.nom} ${client.prenom}.`,
          type: "relance",
          owner: client.owner || auth.currentUser.uid,
        });
      }
      if (isBirthdayToday) {
        newNotifications.push({
          id: client.id,
          message: `L'anniversaire de ${client.nom} ${client.prenom} est aujourd'hui.`,
          type: "birthday",
          owner: client.owner || auth.currentUser.uid,
        });
      }
    });

    setNotifications(newNotifications);
    setUnreadCount(newNotifications.length);

    // Envoyer des emails pour les nouvelles notifications
    newNotifications.forEach((notification) => {
      const client = clients.find((client) => client.id === notification.id);
      if (!client) return;

      let message = "";
      if (notification.type === "relance") {
        message = `
          <div class="${s.modalContent}">
            <p>L'adresse e-mail de <strong>${client.nom} ${client.prenom}</strong> a été copiée dans le presse-papiers.</p>
            <p>Pour relancer le client, ouvrez votre boîte mail et faites un clic droit dans la partie destinataire puis sélectionnez coller. Vous n'avez plus qu'à saisir votre message et envoyer vos promotions à votre client.</p>
            <p>La date de dernière relance a été mise à jour automatiquement.</p>
          </div>`;
      } else if (notification.type === "birthday") {
        message = `
          <div class="${s.modalContent}">
            <p>Souhaitez un joyeux anniversaire à <strong>${client.nom} ${client.prenom}</strong>.</p>
            <p>Son e-mail a été copié dans le presse-papiers. Envoyez-lui une réduction exclusive pour fêter l'événement sur son ou ses produits préférés.</p>
            <p>N'oubliez pas de lui souhaiter un joyeux anniversaire par SMS ou par téléphone au <strong>${client.tel}</strong>.</p>
            <p>La date de dernière relance a été mise à jour automatiquement.</p>
          </div>`;
      }

      fetch("https://api.clubcrm.fr/api/send-notification-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          to: auth.currentUser.email,
          subject: "Nouvelle notification de Club CRM",
          html: message,
        }),
      });
    });
  }, [clients]);

  const toggleNotifications = () => {
    setShowNotifications(!showNotifications);
  };

  const handleNotificationClick = async (notification) => {
    const client = clients.find((client) => client.id === notification.id);
    if (!client) return;

    let message = "";
    const clientRef = doc(
      db,
      "users",
      notification.owner,
      "clients",
      client.id
    );

    if (notification.type === "relance") {
      // Mettre à jour la date de dernière relance avec la date actuelle
      await updateDoc(clientRef, {
        dateDeRelance: new Date().toISOString().split("T")[0],
      });
      message = `
        <div class="${s.modalContent}">
          <p>L'adresse e-mail de <strong>${client.nom} ${client.prenom}</strong> a été copiée dans le presse-papiers.</p>
          <p>Pour relancer le client, ouvrez votre boîte mail et faites un clic droit dans la partie destinataire puis sélectionnez coller. Vous n'avez plus qu'à saisir votre message et envoyer vos promotions à votre client.</p>
          <p>La date de dernière relance a été mise à jour automatiquement.</p>
        </div>`;
    } else if (notification.type === "birthday") {
      // Mettre à jour la date de dernière relance avec la date actuelle
      await updateDoc(clientRef, {
        dateDeRelance: new Date().toISOString().split("T")[0],
      });
      message = `
        <div class="${s.modalContent}">
          <p>Souhaitez un joyeux anniversaire à <strong>${client.nom} ${client.prenom}</strong>.</p>
          <p>Son e-mail a été copié dans le presse-papiers. Envoyez-lui une réduction exclusive pour fêter l'événement sur son ou ses produits préférés.</p>
          <p>N'oubliez pas de lui souhaiter un joyeux anniversaire par SMS ou par téléphone au <strong>${client.tel}</strong>.</p>
          <p>La date de dernière relance a été mise à jour automatiquement.</p>
        </div>`;
    }

    // Copier l'adresse e-mail du client dans le presse-papiers
    navigator.clipboard.writeText(client.mail);

    setModalMessage(message);
    setModalIsOpen(true);

    // Marquer la notification comme lue
    setNotifications(notifications.filter((n) => n.id !== notification.id));
    setUnreadCount(unreadCount - 1);
  };

  const handleCloseModal = () => {
    setModalIsOpen(false);
    setModalMessage("");
  };

  return (
    <div className={s.notificationBellContainer}>
      <div className={s.bellIconWrapper}>
        <FontAwesomeIcon
          icon={faBell}
          className={s.bellIcon}
          onClick={toggleNotifications}
        />
        {unreadCount > 0 && (
          <span className={s.unreadCount}>{unreadCount}</span>
        )}
      </div>
      {showNotifications && (
        <div className={s.notifications}>
          <h2>Notifications</h2>
          {notifications.length === 0 ? (
            <p>Aucune notification.</p>
          ) : (
            <ul>
              {notifications.map((notification) => (
                <li
                  key={notification.id}
                  onClick={() => handleNotificationClick(notification)}
                >
                  {notification.message}
                </li>
              ))}
            </ul>
          )}
        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Notification"
        className={s.Modal}
        overlayClassName={s.Overlay}
      >
        <div>
          <h2>Notification</h2>
          <div dangerouslySetInnerHTML={{ __html: modalMessage }} />
          <button onClick={handleCloseModal}>Fermer</button>
        </div>
      </Modal>
    </div>
  );
};

export default NotificationBell;
