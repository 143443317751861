import React, { createContext, useContext, useState, useEffect } from "react";
import { auth, db } from "../../firebase";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setLoading(true);

      if (user) {
        try {
          // Récupère les données de l'utilisateur uniquement lorsque l'utilisateur est authentifié
          const userDoc = await getDoc(doc(db, "users", user.uid));
          const userData = userDoc.data();

          // Mise à jour de l'utilisateur dans le contexte
          setCurrentUser({ ...user, ...userData });
        } catch (error) {
          console.error(
            "Erreur lors de la récupération des données utilisateur :",
            error
          );
        }
      } else {
        setCurrentUser(null);
      }

      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  // Fonction pour protéger les pages
  const protectPage = (pathname) => {
    const protectedPages = ["/dashboard", "/select-subscription"];
    const publicPages = ["/signup", "/login", "/", "/success"];

    // Vérifie si la page est protégée
    if (protectedPages.includes(pathname)) {
      if (!currentUser) {
        navigate("/login");
      } else if (currentUser.subscriptionStatus !== "active" && pathname !== "/select-subscription") {
        navigate("/select-subscription");
      }
    } else if (publicPages.includes(pathname) && currentUser && currentUser.subscriptionStatus === "active") {
      navigate("/dashboard");
    }
  };

  return (
    <AuthContext.Provider value={{ currentUser, loading, protectPage }}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
