import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import { auth, db } from "../../firebase";
import {
  collection,
  query,
  onSnapshot,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import AddClient from "../AddClient/AddClient";
import NotificationBell from "../NotificationBell/NotificationBell";
import { useAuth } from "../context/authContext";
import s from "./style.module.css";

// Configurer Modal pour l'accessibilité
Modal.setAppElement("#root");

function Dashboard() {
  const { currentUser } = useAuth();
  const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [bulkActionLoading, setBulkActionLoading] = useState(false);
  const [selectedClients, setSelectedClients] = useState([]);
  const [editClient, setEditClient] = useState(null);
  const [messageModalIsOpen, setMessageModalIsOpen] = useState(false);
  const [messageModalContent, setMessageModalContent] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!currentUser) {
      console.log("User not authenticated, redirecting to login page");
      navigate("/login");
      return;
    }

    console.log("User ID:", currentUser.uid);

    const q = query(collection(db, "users", currentUser.uid, "clients"));

    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const clientsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        console.log("Fetched clients data: ", clientsData);
        setClients(clientsData);
        setLoading(false);
      },
      (error) => {
        console.error("Error fetching client data:", error);
        setLoading(false);
      }
    );

    return () => unsubscribe();
  }, [navigate, currentUser]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate("/");
    } catch (error) {
      console.error("Erreur lors de la déconnexion :", error.message);
    }
  };

  const toggleModal = (client = null) => {
    setEditClient(client);
    setModalIsOpen(!modalIsOpen);
  };

  const handleCheckboxChange = (clientId) => {
    setSelectedClients((prevSelected) =>
      prevSelected.includes(clientId)
        ? prevSelected.filter((id) => id !== clientId)
        : [...prevSelected, clientId]
    );
  };

  const handleSelectAll = () => {
    if (selectedClients.length === filteredClients.length) {
      setSelectedClients([]);
    } else {
      setSelectedClients(filteredClients.map((client) => client.id));
    }
  };

  const handleDeleteClient = async (clientId) => {
    try {
      await deleteDoc(doc(db, "users", currentUser.uid, "clients", clientId));
      setClients(clients.filter((client) => client.id !== clientId));
    } catch (error) {
      console.error("Erreur lors de la suppression :", error.message);
    }
  };

  const handleBulkDelete = async () => {
    setBulkActionLoading(true); // Indicateur de chargement lors des actions en masse
    try {
      const promises = selectedClients.map((clientId) =>
        deleteDoc(doc(db, "users", currentUser.uid, "clients", clientId))
      );
      await Promise.all(promises);
      setClients(
        clients.filter((client) => !selectedClients.includes(client.id))
      );
      setSelectedClients([]);
    } catch (error) {
      console.error("Erreur lors de la suppression :", error.message);
    } finally {
      setBulkActionLoading(false);
    }
  };

  const handleExportEmails = async () => {
    setBulkActionLoading(true);
    try {
      const selectedEmails = clients
        .filter((client) => selectedClients.includes(client.id))
        .map((client) => client.mail)
        .join(", ");
      navigator.clipboard.writeText(selectedEmails);

      const promises = selectedClients.map((clientId) => {
        const clientRef = doc(db, "users", currentUser.uid, "clients", clientId);
        return updateDoc(clientRef, {
          dateDeRelance: new Date().toISOString().split("T")[0],
        });
      });
      await Promise.all(promises);

      setMessageModalContent(
        "Les adresses e-mail ont été copiées dans le presse-papiers et les dates de dernière relance ont été mises à jour."
      );
      setMessageModalIsOpen(true);
    } catch (error) {
      console.error("Erreur lors de l'exportation des emails :", error);
    } finally {
      setBulkActionLoading(false);
    }
  };

  const handleCloseMessageModal = () => {
    setMessageModalIsOpen(false);
    setMessageModalContent("");
  };

  const filteredClients = clients.filter((client) =>
    Object.values(client).some((value) =>
      value?.toString().toLowerCase().includes(searchTerm.toLowerCase())
    )
  );

  const formatDate = (dateStr) => {
    const date = new Date(dateStr);
    if (isNaN(date)) return dateStr;
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const handleBillingPortal = async () => {
    const stripeCustomerId = currentUser?.stripeCustomerId;

    if (!stripeCustomerId) {
      alert("Aucun ID client Stripe trouvé. Vous devez être connecté pour accéder à votre portail de facturation.");
      return;
    }

    try {
      const portalResponse = await fetch("https://api.clubcrm.fr/api/create-customer-portal-session", {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ stripeCustomerId }),
      });

      if (!portalResponse.ok) {
        const errorText = await portalResponse.text();
        throw new Error(`Échec de la création de la session de portail: ${errorText}`);
      }

      const portalData = await portalResponse.json();
      if (portalData?.url) {
        window.location.href = portalData.url;
      } else {
        throw new Error("URL du portail introuvable.");
      }
    } catch (error) {
      alert("Échec de l'accès au portail: " + error.message);
      console.log(error);
    }
  };

  return (
    <div className={s.dashboard}>
      <div className={s.header}>
        <div className={s.currentUser}>
          Bienvenue sur ton espace {currentUser?.firstName || "utilisateur"}.
        </div>
        <div className={s.bulkActions}>
          <button className={s.out} onClick={handleLogout}>
            Déconnexion
          </button>
          <button className={s.btn} onClick={() => toggleModal()}>
            Ajouter un Client
          </button>
          <button className={s.btn} onClick={handleBillingPortal}>
            Gérer mon abonnement
          </button>
          {selectedClients.length > 0 && (
            <>
              <button
                className={s.bulkBtn && s.trash}
                onClick={handleBulkDelete}
                disabled={bulkActionLoading} // Désactive les boutons lors de l'exécution
              >
                Supprimer
              </button>
              <button
                className={s.bulkBtn}
                onClick={handleExportEmails}
                disabled={bulkActionLoading}
              >
                Exporter Emails
              </button>
            </>
          )}
          <NotificationBell className={s.notification} clients={clients} />
        </div>
        <div className={s.blocAddSearch}>
          <input
            className={s.search}
            type="text"
            placeholder="Rechercher dans mes clients"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => toggleModal()}
        contentLabel="Ajouter/Modifier Client"
        className={s.Modal}
        overlayClassName={s.Overlay}
      >
        <AddClient
          editClient={editClient}
          onSave={() => setModalIsOpen(false)}
          onDelete={(clientId) => {
            setModalIsOpen(false);
            handleDeleteClient(clientId);
          }}
        />
        <button onClick={() => toggleModal()} className={s.closeBtn}>
          Fermer
        </button>
      </Modal>
      <Modal
        isOpen={messageModalIsOpen}
        onRequestClose={handleCloseMessageModal}
        contentLabel="Message"
        className={s.Modal}
        overlayClassName={s.Overlay}
      >
        <div className={s.modalContent}>
          <h2>Message</h2>
          <p>{messageModalContent}</p>
          <button onClick={handleCloseMessageModal}>Fermer</button>
        </div>
      </Modal>

      {loading ? (
        <p>Chargement des données...</p>
      ) : (
        <div className={s.tableContainer}>
          <table className={s.table}>
            <thead>
              <tr>
                <th>
                  <input
                    type="checkbox"
                    checked={selectedClients.length === filteredClients.length}
                    onChange={handleSelectAll}
                  />
                </th>
                <th>Nom</th>
                <th>Prénom</th>
                <th>Email</th>
                <th>Téléphone</th>
                <th>Adresse</th>
                <th>Date de Naissance</th>
                <th>Date 1er Contact</th>
                <th>Date de dernière Relance</th>
                <th>Date Dernier Atelier</th>
                <th>Produit Favoris 1</th>
                <th>Produit Favoris 2</th>
                <th>Qualification</th>
                <th>Recrutement Possible</th>
                <th>Recommandation</th>
                <th>Commentaires</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {filteredClients.map((client) => (
                <tr key={client.id}>
                  <td>
                    <input
                      type="checkbox"
                      checked={selectedClients.includes(client.id)}
                      onChange={() => handleCheckboxChange(client.id)}
                    />
                  </td>
                  <td data-label="Nom">{client.nom || ""}</td>
                  <td data-label="Prénom">{client.prenom || ""}</td>
                  <td data-label="Email">{client.mail || ""}</td>
                  <td data-label="Téléphone">{client.tel || ""}</td>
                  <td data-label="Adresse">{client.adresse || ""}</td>
                  <td data-label="Date de Naissance">
                    {formatDate(client.birthday) || ""}
                  </td>
                  <td data-label="Date 1er Contact">
                    {formatDate(client.date1erContact) || ""}
                  </td>
                  <td data-label="Date de dernière Relance">
                    {formatDate(client.dateDeRelance) || ""}
                  </td>
                  <td data-label="Date Dernier Atelier">
                    {formatDate(client.dateDernierAtelier) || ""}
                  </td>
                  <td data-label="Produit Favoris 1">
                    {client.produitFavoris1 || ""}
                  </td>
                  <td data-label="Produit Favoris 2">
                    {client.produitFavoris2 || ""}
                  </td>
                  <td data-label="Qualification">
                    {client.qualification.join(", ") || ""}
                  </td>
                  <td data-label="Recrutement Possible">
                    {client.recrutementPossible ? "Oui" : "Non"}
                  </td>
                  <td data-label="Recommandation">
                    {client.recommandation || ""}
                  </td>
                  <td data-label="Commentaires">{client.commentaires || ""}</td>
                  <td data-label="Actions">
                    <button onClick={() => toggleModal(client)}>
                      Modifier
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default Dashboard;
